<template>
    <div id="testimonial">
        <vueper-slides
            fade
            :bullets="false"
            :arrows="false"
            class="no-shadow"
            duration="8000"
            :touchable="false"
            :fixed-height="slideHeight"
            ref="slideshow"
            >
            <vueper-slide
                v-for="(slide, i) in reviews"
                :key="i"
                class="testimonialSlide">
                <template v-slot:content>
                    <div class="slideContent">
                        <h2>
                            {{slide.Excerpt}}
                        </h2>
                        <p>
                            "{{ slide.Review }}"
                        </p>
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "Testimonial",
    components: { VueperSlides, VueperSlide },
    data(){
        return {
            index: 0,
            currentHeight: 300
        }
    },
    computed:{
        reviews(){
            return this.$store.getters.reviews.filter(review => review.Homepage);
        },
        slideHeight(){
            if(window.innerWidth > 780){
                return "200px"
            } else {
                return "320px";
            }
        }
    },
    mounted(){
        setInterval(()=>{
            this.$refs.slideshow.next()
        }, 8000)
    }
}
</script>
<style lang="scss">
#testimonial .testimonialSlide{
    top: unset !important;
    left: unset !important;
    transform: unset !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:hover{
        cursor: default;
    }
}

#testimonial .testimonialSlide .slideContent{
    max-width: 700px;
    margin: 0 17.5%;
    height: 200px;
}

#testimonial .testimonialSlide .slideContent h2{
    font-family: $secondaryFont;
    font-style: italic;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 20px;
}


@media (max-width: 780px) { 
    #testimonial .testimonialSlide .slideContent{
        height: 300px;
    }
    #testimonial .testimonialSlide .slideContent h2{
        top: unset;
        left: unset;
        transform: unset;
        margin: 2rem 0;
    }
}
@media (max-width: 576px) {
    #testimonial .testimonialSlide .slideContent h2{
        font-size: 1.5rem;
    }
}
</style>