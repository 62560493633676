<template>
    <div id="home">
        <BackgroundSlideshowVue />
        <div id="intro">
            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000" class="reviews-header">
                <img src="/images/badge-1.png" alt="good reviews">
                <img src="/images/badge-2.png" alt="good reviews">
            </div>
            <Testimonial data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000" />
            <div data-aos="fade-up" data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000" class="button-container">
                <router-link class="btn" to="/about#reviews">
                    Read Bob's Reviews
                </router-link>
            </div>
            <hr class="divider">
            <HomepageSlideshow />
        </div>
        <div id="agent">
            <div class="profilePhoto"
                data-aos="fade-right"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000">
                <img src="/images/site_owner.jpg" alt="Bob Safranski"/>
            </div>
            <div class="container" 
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
                v-bind:data-aos-delay="300"
                >
                <div class="content">
                    <h1>{{introTitle}}</h1>
                    <p v-html="homePageContent"></p>
                    <router-link to="/about" class="btn">
                        MORE
                    </router-link>
                </div>
            </div>
        </div>
        <div id="app-container">
            <div class="container" 
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000"
                v-bind:data-aos-delay="300"
                >
                <div class="content">
                    <h1>{{appTitle}}</h1>
                    <p v-html="appContent"></p>
                    <a href="https://zenlist.com/a/robert.safranski" class="btn" target="_blank">
                        JOIN NOW
                    </a>
                </div>
            </div>
            <div class="profilePhoto"
                data-aos="fade-left"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="1000">
                <img src="/images/asset-zenlist.png" alt="Bob Safranski"/>
            </div>
        </div>
    </div>
</template>

<script>
import Testimonial from '../components/Testimonial'
import HomepageSlideshow from '../components/HomepageSlideshow.vue'
import BackgroundSlideshowVue from '../components/BackgroundSlideshow.vue'

export default {
    name: "Home",
    components: {
        HomepageSlideshow,
        Testimonial,
        BackgroundSlideshowVue
    },
    metaInfo: {
        title: 'Bob Safranski | Mindful real estate',
        meta: [{
            name: 'description', 
            content: "Bob Safranski | Mindful real estate"
        }]
    },
    data(){
        return{
            introTitle: "Get to Know Bob",
            homePageContent: "As a licensed Illinois real estate broker since 2002, Bob Safranski is fluent in French and conversant in Japanese, both enriched by living and studying in those countries during college. Initially working with a French-Japanese joint venture, he transitioned to real estate to optimally serve clients. In 2005, he founded Saffron Realty Group, which grew to 35 agents and later merged with @properties, weathering tough market downturns. A tech enthusiast, Bob launched offMLS in 2014 and co-created Zenlist, a premier search and collaboration platform. While he's eyeing new projects, Bob's core commitment remains flawless real estate transactions for every client.",
            appTitle: "Join Bob on Zenlist",
            appContent: "Ready to revolutionize your property search? Dive into both MLS and exclusive off-market listings alongside your agent. Tap here to explore Zenlist and embark on your property journey with Bob!",
        }
    },
}   
</script>

<style lang="scss" scoped>
#agent{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 75px 15% 75px;
    .container{
        flex: 1;
        .content{
            text-align: left;
            margin: 1rem 0;
            max-width: 600px;
            h1{
                font-family: $secondaryFont;
                font-weight: 500;
            }
            p{
                margin: 3rem 0;
            }
        }
    }
    .profilePhoto{
        width: 40%;
        padding-right: 3rem;
        overflow: hidden;
        img{
            object-fit: cover;
            width: 100%;
            display: block;
        }
    }
}

#app-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 75px 15% 75px;
    overflow-x: hidden;
    .container{
        flex: 1;
        .content{
            text-align: left;
            margin: 1rem 0;
            max-width: 600px;
            h1{
                font-family: $secondaryFont;
                font-weight: 500;
            }
            p{
                margin: 3rem 0;
            }
        }
    }
    .profilePhoto{
        width: 40%;
        padding-left: 3rem;
        overflow-x: hidden;
        img{
            object-fit: cover;
            width: 100%;
            display: block;
        }
    }
}

#intro{
    width: 100%;
    height: 100%;
}

.reviews-header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    img{
        width: 140px;
        margin: 0 10px;
    }
}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.divider{
    width: 90%;
    opacity: 0.5;
    margin: 60px auto;
}

@media (max-width: 780px) {
    #agent, #app-container{
        .profilePhoto{
            padding-right: 2rem;
            width: 35%;
        }
    }
}
@media (max-width: $mobile) { 
    #agent, #app-container{
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
        margin-top: 0rem;
        .container{
            flex: 1;
            .content{
                text-align: center;
                margin: 2rem;
                h1{
                    text-transform: unset;
                    letter-spacing: 2px;
                }
                p{
                    text-align: left;
                    margin: 1rem 0;
                }
                a{
                    margin-top: 2rem;
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 6px;
                }
            }
        }
        .profilePhoto{
            margin-top: 0;
            width: 80%;
            text-align: center;
            padding-right: 0;
            padding-left: 0;
            margin: 2rem;
            img{
                display: block;
                object-fit: cover;
                object-position: 0 0;
            }
        }
    }
    #app-container{
        flex-direction: column-reverse;
    }
}

</style>