<template>
    <div id="background-slideshow">
        <vueper-slides
            class="slideshow no-shadow"
            :fixed-height="true"
            autoplay
            infinite
            fade
            :pauseOnHover="false"
            :arrows="false"
            :bullets="false"
            duration="5000">
            <vueper-slide v-for="(image,i) in randomImages" :key="i" :image="image" />
        </vueper-slides>
        <div class="scrolldown"><a href="#featured-listings" class="btn arrow bounce" aria-label="Go to feature listgins section"></a></div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';

export default {
    name: "BackgroundSlideshow",
    components: { VueperSlides, VueperSlide },
    data() {
        return {
            imageURL: '/images/slideshow/',
            images: [
                `/images/slideshow/bobs-0001.jpg`,
                `/images/slideshow/bobs-0002.jpg`,
                `/images/slideshow/bobs-0003.jpg`,
                `/images/slideshow/bobs-0004.jpg`,
                `/images/slideshow/bobs-0005.jpg`,
                `/images/slideshow/bobs-0006.jpg`,
                `/images/slideshow/bobs-0007.jpg`,
                `/images/slideshow/bobs-0010.jpg`,
                `/images/slideshow/bobs-0011.jpg`,
                `/images/slideshow/bobs-0012.jpg`,
                `/images/slideshow/bobs-0014.jpg`,
                `/images/slideshow/bobs-0015.jpg`,
                `/images/slideshow/bobs-0016.jpg`,
                `/images/slideshow/bobs-0017.jpg`,
                `/images/slideshow/bobs-0018.jpg`,
                `/images/slideshow/bobs-0019.jpg`,
                `/images/slideshow/bobs-0020.jpg`,
                `/images/slideshow/bobs-0021.jpg`,
            ]
        }
    },
    computed:{
        randomImages(){
            let images = this.images;
            for (let i = this.images.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                let temp = images[i];
                images[i] = images[j];
                images[j] = temp;
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
#background-slideshow{
    height: 100vh;
    margin-top: -59px;
}
.slideshow{
    height: 100%;
}

.scrolldown{
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
    border: none;
    z-index: 19;
    left: 50%;
    transform: translateX(-50%)
}


.arrow{
    display: inline-block;
    bottom: 0;
    width: 39px;
    height: 24px;
    background: url(/images/dn-arrow.png) no-repeat 50%;
}
.bounce{
    animation: bounce 2s infinite;
}
@keyframes bounce {
    0% {
        transform: translateY(-45px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
</style>